import "../styles/global.scss";

import {
  CopyrightFooter,
  HeaderWithLogo,
} from "../components/HeaderFooter/HeaderFooter";
import styled, { ThemeProvider } from "styled-components";

import { Link } from "gatsby";
import React from "react";
import staticText from "../data/staticDataCareers.json";
import theme from "../utils/Theme";

const Careers = () => {
  return (
    <ThemeProvider theme={theme}>
      <HeaderWithLogo />
      <Wrapper>
        <Heading>JOIN US AT <MobileLineBreak>EDBA SOFTWARE LLP</MobileLineBreak></Heading>
        {Object.values(staticText).map((paragraph, index) => (
          <Paragraph key={index}>{paragraph}</Paragraph>
        ))}
        <Subheading>Open Positions</Subheading>
        <KekaWrapper>
          <iframe
            src="https://edba.keka.com/careers/api/embedjobs/470f25f6-45cd-49b5-a3ae-e5b29a0efa2c"
            frameborder="0"
            height="100%"
            width="100%"
          ></iframe>
        </KekaWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};

// min-height: calc(100vh - 21rem); --> to keep footer at bottom dynamically
const Wrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 21rem);
  @media ${({ theme }) => theme.media.small} {
    padding: 0 2rem;
    max-width: 100%;
  }
`;

const Heading = styled.h1`
  margin-top: 4rem;
  font-size: 4rem;
  @media ${({ theme }) => theme.media.small} {
    font-size: 2.4rem;
  }
`;

const Paragraph = styled.p`
  margin-top: 2rem;
  text-align: justify;
  text-justify: inter-word;
`;

const Subheading = styled.h2`
  font-size: 2.8rem;
  margin-top: 2.4rem;
  margin-bottom: 16px;
  @media ${({ theme }) => theme.media.small} {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
`;

const KekaWrapper = styled.div`
  margin: 0px -15px;
  height: 100vh;
`;

const MobileLineBreak = styled.span`
  display: inline-block;
  @media ${({ theme }) => theme.media.small} {
    padding-top: 0.6rem;
  }
`;

export default Careers;
